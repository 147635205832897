body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  z-index: -1;
  background-color: #171d23;
}

.header {
  background-color: #222e34;
  padding: 20px;
  text-align: center;
}
.header > h1 {
    font-size: 60px;
    color: #fd820a;
}
.div1 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
dl {
  width:500px;
  margin-right:2em;
  border: 3px solid #fd820a;
  border-radius: 8px;
  background-color: #fd820a;
}
  
dt {
  font-weight:bold;
  font-size:0.9em;
  margin-bottom:0.7em;
  color:  #222e34;
  left: 100px;
}
dt a {
   color:  #222e34;
}
  
.div1 {
  color: #fd820a;
}
.h31 {
  color: #fd820a;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #fd820a;
  position: absolute;
  right: 500px;
  top: 150px;
  margin-right: 20em;
}

li {
  float: left;
}

li Link {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  color:#222e34;
}

li a {
  background-color: #fd820a;
  color: #222e34;
}
.price {
  margin: auto;
  width: 60%;
  border: 2px solid #fd820a;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 10px 10px 5px 0px black;
  border: 2px solid #fd820a;
  background-color: #fd820a;
}
h1 {
  color: #fd820a;
}
a {
  text-decoration: none;
  color: #fd820a;
}
textarea {
  width: 300px;
  height: 150px;
  background-color: #fd820a;
   border-color: #fd820a;
}
input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fd820a;
  border-color: #fd820a;
}
.submit {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  border-color: #fd820a;
}
.div3 {
  display: flex;
  justify-content: center;
}

table, td, th {  
  border: 1px solid #ddd;
  text-align: left;
  background-color: #fd820a;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 15px;
}

.delete {
  background-color: #fd820a;
  color: #222e34;
  border: 2px solid #fd820a;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-left: 23em;
}

/* Modal content */
.modal-content {
  background-color: #171d23;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 4px;
  max-width: 600px;
  width: 90%;
  margin: auto;
  position: relative;
  margin-top: 200px;
}

/* Modal header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: #fd820a;
}

/* Modal close button */
.modal-close {
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
  color: #fd820a;
}

/* Form input fields */
.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 6px 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Form submit button */
.modal-content .submit {
  background-color: #fd820a;
  color: white;
  padding: 10px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.modal-content .submit:hover {
  background-color: #222e34;
}

.modal-button {
  width:500px;
  height: 50px;
  margin-right:2em;
  border: 3px solid #fd820a;
  border-radius: 8px;
  background-color: #fd820a;
  color: #222e34;
  padding: 10px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #171d23;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #fd820a;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (min-width: 320px) and (max-width: 480px) {
  .header > h1 {
   display: none;
  }
  .header {
   margin-right: 0px;
   background-image: none;
  }
  ul {
   list-style: none;
   position: relative;
   right: 0px;
   top: 0px;
   margin-right: 0px;
  }
  li {
   float: none;
  }
  li a {
   display: block;
   text-align: center;
  }
}


@media (min-width: 481px) and (max-width: 768px)  {
  .header > h1 {
    display: none;
   }
   .header {
    margin-right: 0px;
    background-image: none;
   }
   ul {
    list-style: none;
    position: relative;
    right: 0px;
    top: 0px;
    margin-right: 0px;
   }
   li {
    float: none;
   }
   li a {
    display: block;
    text-align: center;
   }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .header > h1 {
    display: none;
   }
   .header {
    margin-right: 0px;
    background-image: none;
   }
   ul {
    list-style: none;
    position: relative;
    right: 0px;
    top: 0px;
    margin-right: 0px;
   }
   li {
    float: none;
   }
   li a {
    display: block;
    text-align: center;
   }
}


